<template>
    <div>
        <label class="block text-xs sm:text-sm font-medium leading-5" :class="theme === 'dark' ? 'text-white' : 'text-gray-900' ">{{ question_container.question }}</label>
        <div class="flex flex-wrap mt-1 rounded border border-gray-400 overflow-hidden">
            <div v-for="option in options" v-bind:key="option.id" :class="options.length > 2 ? 'w-full sm:w-1/3' : 'w-full sm:w-1/2'">
                <button
                    type="option-active"
                    :class="option.id == value 
                    ? 'option-active w-full focus:outline-none active:outline-none'
                    : 'option w-full focus:outline-none active:outline-none'"
                    v-on:click.prevent="updateValue(option.id)">{{ option.name }}
                </button>
            </div>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
        </template>
    </div>
</template>

<script>

export default {
    name: "QuestionWithOptions",
    props: {
        question_container: {type: Object, required: true},
        value: {required: true},
        errors: {default: () => []},
        theme: {required: true},
    },
    computed:{
        options: function (){
            return this.question_container.options;
        },
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
    },
    methods:{
        updateValue(value){
            this.$emit('update:value', value);
            // console.log('updateValue: ', value);
        }
    },
}
</script>

<style scoped>

</style>
