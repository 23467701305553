import axios from 'axios';

const index_sport_path = "v1/coach/sport/"; // get
const get_sport_positions_path = "v1/coach/position/"; // get

function indexSport()
{
    return new Promise((resolve, reject) =>{
        axios.get(index_sport_path)
            .then((response)=>{
                resolve(response.data.sports);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function getSportPositions()
{
    return new Promise((resolve, reject) =>{
        axios.get(get_sport_positions_path)
            .then((response)=>{
                resolve(response.data.sport.positions);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { indexSport, getSportPositions };