<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    <i18n-t keypath="profile.title" />
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 lg:gap-x-32 gap-y-8 md:gap-y-12 mt-4 md:mt-6">
                    <div class="w-full">
                        <FormSection :title="$t('profile.primary.title')" :hasSaveButton="true" @submit="sendForm1">
                            <ImageInput v-model:value="form1.profile_image" :url_value="current_user_image" v-model:errors="errors1.profile_image" cropper="true" :label="$t('profile.image')" theme="dark" class="mt-4 md:mt-6"/>
                            <TextAreaInput v-model:value="form1.about" v-model:errors="errors1.about" :label="$t('profile.about.title')" :placeholder="$t('profile.about.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div> 

                    <div class="w-full">
                        <FormSection :title="$t('profile.sport.title')" :hasSaveButton="true" @submit="sendForm2"> 
                            <SelectInput v-model:value="form2.field_position" :options="field_position_options" v-model:errors="errors2.field_position" :label="$t('profile.field_position')" id="field_position" theme="dark" class="mt-4 md:mt-6" />
                            <TextAreaInput v-model:value="form2.expertise" v-model:errors="errors2.expertise" :label="$t('profile.expertise.title')" :placeholder="$t('profile.expertise.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>

                    <!-- <div class="w-full">
                        <FormSection :title="$t('profile.club.title')" :hasSaveButton="true" @submit="sendClubForm"> 
                            <div class="text-white">
                                huidige club:
                            {{ user.club }}
                            </div>

                            <SelectInput 
                                v-model:value="clubForm.club_id" 
                                v-model:errors="clubErrors.club_id" 
                                :options="clubOptions" 
                                :label="$t('profile.club')" 
                                id="club" 
                                theme="dark" 
                                class="mt-4 md:mt-6" 
                            />

                            <p class="text-white">Staat je club er niet tussen? neem contact op met bla bla bla</p>
                        </FormSection>
                    </div> -->
                    
                    
                    <div class="w-full">
                        <FormSection :title="$t('profile.available.title')" :subtitle="$t('profile.available.subtitle')" :hasSaveButton="true" @submit="sendForm3">
                            <QuestionWithOptions v-model:value="form3.available" v-model:errors="errors3.available" :question_container="{ 
                                question: $t('profile.available.question'), 
                                options: [
                                    { id: 0, name: $t('profile.available.false') }, 
                                    {id: 1, name: $t('profile.available.true')}
                                ]}" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>      

                     <div class="w-full">
                        <FormSection :title="$t('profile.hour_price.title')" :hasSaveButton="true" @submit="sendForm4">
                            <TextInput v-model:value="form4.hour_price" v-model:errors="errors4.hour_price" :label="$t('profile.hour_price.title')" number placeholder="0,00" theme="dark" class="mt-4 md:mt-6" />                       
                        </FormSection>
                    </div> 
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import { update_profile } from "@/helper/api/EditProfileHelper";
import { getSportPositions, indexSport } from "@/helper/api/SportHelper";
import DashboardWrapper from "@/wrappers/Dashboard";
import FormSection from "@/wrappers/partials/FormSection";
import TextInput from "@/components/forms/TextInput";
import SelectInput from "@/components/forms/SelectInput";
import ImageInput from "@/components/forms/ImageInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import QuestionWithOptions from "@/components/forms/QuestionWithOptions";
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
import { forcePagination } from "../helper/api/misc/ForcePaginationHelper";
import { indexClub, joinClub } from "../helper/api/ClubHelper";
const $t = i18n.global.t;

export default {
    name: "EditProfile",
    components: {
        DashboardWrapper,
        FormSection,
        TextInput,
        SelectInput,
        ImageInput,
        TextAreaInput,
        QuestionWithOptions,
    },
    
    methods:{
        sendForm1 () {
            update_profile(this.form1)
                .then(()=>{
                    messages.success($t('profile.primary.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors1 = data.errors;
                });
        },
        sendForm2 () {
            update_profile(this.form2)
                .then(()=>{
                    messages.success($t('profile.sport.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors2 = data.errors;
                });
        },
        sendForm3 () {
            update_profile(this.form3)
                .then(()=>{
                    messages.success($t('profile.available.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors3 = data.errors;
                });
        }, 
        sendForm4 () {
            update_profile(this.form4)
                .then(()=>{
                    messages.success($t('profile.hour_price.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors4 = data.errors;
                });
        }, 
        sendClubForm() {
            joinClub(this.clubForm.club_id)
                .then(()=>{
                    messages.success($t('profile.club_change.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.clubErrors = data.errors;
                });
        },
        async loadClubOptions() {
            this.clubOptions = await forcePagination(indexClub)
                .then(clubs => clubs.map(club => ({ key: club.id, text: club.name})))
        },
        loadSports(){
            indexSport()
                .then(sports => {
                    this.sports_options = sports.map(sport => {return {key: sport.id, text: sport.name}});
                })
                .catch(err => console.log(err));
        },
        loadSportPositions(){
            getSportPositions()
                .then(positions => {
                    this.field_position_options = positions.map(pos => {return { key: pos.abbreviation, text: pos.name }});
                })
                .catch(err => console.log(err));
        },
        setFormFields() {
            const user = this.user;
            for( const p in this.form1){
                this.form1[p] = (p in user ? user : this.form1)[p];
            }
            for( const p in this.form2){
                this.form2[p] = (p in user ? user : this.form2)[p];
            }
            for( const p in this.form4){
                this.form4[p] = (p in user ? user : this.form4)[p];
            }
            this.form2.field_position = user.position ?? "";
            this.current_user_image = user.thumbnail;       
            
            this.clubForm.club_id = user.club?.id
        },
    },
    created(){
        const user = this.user;
        const user_attributes_sanitized = {};
        for( const p in this.form3){
            user_attributes_sanitized[p] = (p in user ? user : this.form3)[p];
        }
        this.form3 = user_attributes_sanitized;
        
        this.loadSports();
        this.loadSportPositions();
        this.loadClubOptions();
        this.setFormFields();
    },
    data() {
        return {
            form1: {
                profile_image: undefined,
                about: '',
            }, 
            form2: {
                field_position: '',
                expertise: '',
                active: false,
            },
            form3: {
                available: 0,
            },
            form4: {
                hour_price: '',
            },
            clubForm: {
                club_id: undefined
            },
            errors1: {
                profile_image: [],
                about: [],
            },
            errors2: {
                field_position: [],
                expertise: [],
                active: [], 
            },
            errors3: {
                available: [],
            },
            errors4: {
                hour_price: [],
            },
            clubErrors: {
                club_id: undefined
            },
            sports_options: [],
            field_position_options: [],
            current_user_image: '',
            clubOptions: [],
        }
    },
    computed: {
        user () {
            return this.$store.getters['getUser'];
        }
    },
}
</script>

<style scoped>
</style>