import axios from 'axios';
import store from '@/store';

const update_profile_path = "v1/coach/account"; // patch
// const update_email_path = "";
// const update_settings_path = "";

function update_profile(data)
{
    return new Promise((resolve, reject) => {

        if(!data || data === {} || hasOnlyEmptyFields(data)){
            reject({message: 'Vul iets in', errors: []});
        }

        let formData = new FormData();

        for(const prop in data){
            if (data[prop] === undefined) {
                // Do nothing
            } 
            else if (typeof data[prop] == "string" && data[prop] !== ""){
                formData.append(prop, data[prop]);
            } 
            else if (typeof data[prop] == "number"){
                formData.append(prop, data[prop].toString());
            }
            else if (typeof data[prop] == "boolean"){
                formData.append(prop, (data[prop] ? '1': '0'));
            } else {
                formData.append(prop, data[prop]);
            }
        }

        // console.log('---------------------------------------------------');
        // for(let [name, value] of formData) {
            // console.log(`${name} = ${value}`, typeof value); // key1=value1, then key2=value2
        //   }
        // console.log('---------------------------------------------------');

        const url = update_profile_path + '?_method=patch';
    
        axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
                // console.log('response', response.data.coach);
                store.commit('setUser', response.data.coach);
                resolve();
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function hasOnlyEmptyFields(object)
{
    // console.log('object', object);
    for(const prop in object){
        if(typeof object[prop] !== 'string' || object[prop] !== ""){
            return false;
        } 
    }
    return true;
}

function update_email(email, email_confirmation)
{
    const data = {email, email_confirmation};
    console.log(data);

    return Promise.reject({message: 'Updaten van Email is nog niet mogelijk', errors: []});

    // return new Promise((reject) =>{
    //     axios.post(update_email_path, data)
    //         .then(() => {
    //             resolve();
    //         })
    //         .catch((err) => {
    //             reject(err.response.data);
    //         });
    // });
}

function update_password(current_password = "", password = "", password_confirmation = "")
{
    const url = update_profile_path + '?_method=patch';

    return new Promise((resolve, reject) =>{
        axios.post(url, {current_password, password, password_confirmation})
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function update_settings(formData)
{

    console.log(formData);

    return Promise.reject({message: 'Updaten van instellingen is nog niet mogelijk', errors: []});

    // return new Promise((resolve, reject) =>{
    //     axios.post(update_settings_path, formData)
    //         .then(() => {
    //             resolve();
    //         })
    //         .catch((err) => {
    //             reject(err.response.data);
    //         });
    // });
}



export { update_profile, update_email, update_password, update_settings };