export async function forcePagination(getDataCallback, dataKey = "data") {
  let list = [];
  let nextPage = 1;
  let isRunning = true;

  while (isRunning) {
    const response = await getDataCallback(nextPage);
    const data = response[dataKey] ?? [];
    if (data.length === 0) {
      isRunning = false;
    } else {
      list = [...list, ...data];
      nextPage++;
    }
  }

  return list;
}
