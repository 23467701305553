<template>
     <div>
        <h3 class="border-b text-white uppercase text-md font-extrabold">{{ title }}</h3>
        <p v-if="subtitle" class="mt-4 md:mt-6 text-xs md:text-sm leading-5 text-white">{{ subtitle }}</p>
    </div>
    <form @submit.prevent="$emit('submit')">
        <slot />
        <div v-if="hasSaveButton" class="text-right mt-4 md:mt-6">
            <button type="submit" @click.prevent="$emit('submit')" class="button">
                <i18n-t keypath="general.save" />
            </button>
        </div>    
    </form>
</template>

<script>
export default {
    props: ['title', 'subtitle', 'hasSaveButton'],
    emits: ['submit'],
}
</script>