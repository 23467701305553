import axios from 'axios';
import store from '@/store';

export function indexClub(page = 1, filter = "") {

    const queries = [
        `page=${page}`
    ]  

    if (filter) {
        queries.push(`filter[name]=${filter}`)
    }

    const url = `v1/coach/club?` + queries.join("&")

    return axios.get(url).then(response => response.data)
}

function addClubToUser (response) {
    const user = store.getters['getUser'];
    
    user.club = response.data.club

    store.commit('setUser', user);
}

export function joinClub(clubId) {
    const url = `v1/coach/club` 
    
    return axios.post(url, {
        club_id: clubId
    })
        .then(addClubToUser)
}